<!-- 常见问题 -->
<template>
  <div class="commonProblem">
    <header> 常见问题</header>

    <div
      v-for="(item,index) in list"
      :key="index"
    >
      <p>{{index+1}}.{{item.art_title}}</p>
      {{item.art_content}}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        // {
        //   art_title: "卖卡多久审核？个人中心余额提现多久到账？",
        //   art_content:
        //     "万里通积分/壹卡会/携程任我行/京东E卡/万商卡/彩虹卡2小时左右审核成功，提现2小时左右到账其余卡类1-3个工作日审核并到账",
        // },
        // {
        //   art_title: "卖卡记录显示成功了个人中心余额提现怎么没到账？",
        //   art_content:
        //     "个人中心-余额提现到支付宝（秒到账）银行卡（24小时内到账），核对提现账户信息是否有误，如果错误了重新修改再提现即可",
        // },
        // {
        //   art_title: "账户积分兑换联系18770067330（微信同号）",
        //   art_content: "",
        // },
        // {
        //   art_title: "其他问题联系在线客服",
        //   art_content: "",
        // },
      ],
    };
  },

  components: {},

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.$axios
        .get("/api/Web/getArticleList", {
          params: {
            arc_code: "question",
          },
        })
        .then((res) => {
          if (res.code == "000") {
            console.log(res);
            this.list = res.data.rows;
          }
        });
    },
  },
};
</script>
<style lang='scss'>
.commonProblem {
  margin: 10px;
  font-size: 14px;
  font-weight: 500;
  header {
    padding-top: 10px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  }
  p {
    font-size: 15px;
    font-weight: 600;
  }
}
</style>